// -------------------------------------------------------

import classNames from "classnames"
import PropTypes from "prop-types"

// ---------------------------------------------------------

import Dropdown from "./dropdown"
import Search from "../search"

// ---------------------------------------------------------

import {
  navigation_container,
  navigation,
  navigation_is_hiding,
  navigation_is_mobile,
  navigation_is_showing,
  hide_on_desktop
} from "./styles.module.scss"

// ---------------------------------------------------------

const Navigation = (props) => {
  let { buttons, links = [], navigationIsShowing, searchIsActive, onSearchActiveChange } = props

  // -------------------------------------------------------

  const handleMobileSearch = (isActive) => {
    if (onSearchActiveChange) {
      onSearchActiveChange(isActive)
    }
  }

  return (
    <nav
      className={classNames(navigation_container, {
        [navigation_is_hiding]: !navigationIsShowing,
        [navigation_is_showing]: navigationIsShowing
      })}
    >
      <ul className={navigation}>
        <Search className={hide_on_desktop} handleSearchIsActive={handleMobileSearch} />

        {links.map((item) => {
          return (
            <li key={item.label}>
              <Dropdown searchIsActive={searchIsActive} {...item} />
            </li>
          )
        })}
        <li className={classNames(navigation_is_mobile)}>
          {buttons.map((button) => {
            return button
          })}
        </li>
      </ul>
    </nav>
  )
}

// -------------------------------------------------------

Navigation.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.object),

  /**
   * Specifies an array of link objects used to build the list
   */
  links: PropTypes.arrayOf(
    PropTypes.shape({
      children: PropTypes.arrayOf(
        PropTypes.shape({
          heading: PropTypes.string,
          links: PropTypes.arrayOf(
            PropTypes.shape({
              label: PropTypes.string.isRequired,
              url: PropTypes.string
            })
          )
        })
      ),
      dropdownTheme: PropTypes.oneOf(["2-column-60-40", "2-column-40-60", "3-column", "1-column"]),
      heading: PropTypes.string,
      label: PropTypes.string.isRequired,
      url: PropTypes.string
    })
  ).isRequired,

  /**
   * Specifies a boolean value passed down from the Header
   * to inform this component when the menu on smaller screens is showing
   */
  navigationIsShowing: PropTypes.bool,

  /**
   * Specifies a boolean value passed down from the Header
   * to inform each child know when the search bar is showing
   */
  searchIsActive: PropTypes.bool,

  onSearchActiveChange: PropTypes.func
}

export default Navigation
