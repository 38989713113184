import React from "react"
import Block from "../components/block"
import Image from "@components/image"

const RenderBlocks = (blocks = []) => {
  return (blocks || [])
    .filter((block) => block !== null)
    .map((block, idx) => {
      if (block.__typename === "Image") {
        return (
          // eslint-disable-next-line jsx-a11y/alt-text
          <Image
            key={idx}
            {...block}
            // {...block.image}
            sizes="(min-width: 840px) 50vw, 100vw"
          />
        )
      }

      return (
        <Block
          key={idx}
          template={block.__typename === "Snippet" ? block.template : block.__typename}
          {...block}
        />
      )
    })
}

export default RenderBlocks
