import { useContentfulLiveUpdates } from "@contentful/live-preview/react"
import FlexibleData from "../lib/flexibleData"
import { fetchRecommended } from "../lib/fetchRecommended"
import FlexiblePage from "../templates/flexible/template"

export async function getStaticProps(context) {
  const preview = process.env.NODE_ENV === "development" || context.draftMode || false
  const previewEnv = context?.previewData?.environment || null

  const slug = "home"
  const data = (await FlexibleData.fetch(slug, preview, previewEnv)) ?? []
  const recommended = data.showRecommended
    ? await fetchRecommended(data?.recommendedCollection?.items, slug, undefined, preview)
    : null

  return {
    props: { preview, data, recommended }
  }
}

const Home = ({ preview, data, recommended }) => {
  const liveData = useContentfulLiveUpdates(data)

  return <FlexiblePage data={liveData} preview={preview} recommended={recommended}></FlexiblePage>
}

export default Home
