import React, { useEffect, useState } from "react"
import { ContentfulLivePreviewProvider } from "@contentful/live-preview/react"

import { useRouter } from "next/router"
import { DataProvider } from "../DataProvider"
import { useMediaQuery } from "react-responsive"
import Munchkin from "../scripts/Munchkin"

import "../../public/fonts/global-fonts.css"
import "../styles/libs/sanitize.scss"
import "../styles/global-styles.scss"
import "../styles/global-utilities.scss"
import "swiper/swiper-bundle.css"

// ---------------------------------------------------------

// eslint-disable-next-line react/prop-types
export default function MyApp({ Component, pageProps }) {
  const router = useRouter()
  const [email, setEmail] = useState("")
  const [swiftInit, setSwiftInit] = useState(false)
  const isDesktop = useMediaQuery({ query: "(min-width: 1140px)" })

  const munchkinPageView = (url) => {
    if (window !== undefined && window.Munchkin) {
      window.Munchkin.munchkinFunction("visitWebPage", {
        url
      })
    }
  }

  // remove existing swiftype scripts
  const removeSwift = () => {
    const stScript = document.getElementById("swifttype")
    const stInstall = document.getElementById("swiftInstall")
    stScript?.parentNode.removeChild(stScript)
    stInstall?.parentNode.removeChild(stInstall)
  }

  const addSwift = () => {
    // set new script on each page load
    var st = document.createElement("script")
    st.type = "text/javascript"
    st.async = true
    st.id = "swifttype"
    st.src = "/js/swifttype.js"
    st.onerror = function onError(e) {
      console.error("Swifttype failed to load", e)
    }
    document.getElementsByTagName("head")[0].appendChild(st)
  }

  /**
   * Resets the swiftype script on page changes
   * Nextjs does not deal well with page changes and scripts hooking into the DOM
   * See https://github.com/vercel/next.js/discussions/17919 for reference
   */
  useEffect(() => {
    if (!swiftInit) {
      addSwift()
      setSwiftInit(true)
    }

    // Listen to router changes to reset script
    router.events.on("routeChangeStart", removeSwift)
    router.events.on("routeChangeComplete", addSwift)

    return () => {
      router.events.off("routeChangeStart", removeSwift)
      router.events.off("routeChangeComplete", addSwift)
    }
  }, [router.events, swiftInit])

  useEffect(() => {
    return () => {
      removeSwift()
      addSwift()
    }
  }, [isDesktop])

  useEffect(() => {
    router.events.on("routeChangeComplete", munchkinPageView)

    return () => {
      router.events.off("routeChangeComplete", munchkinPageView)
    }
  }, [router.events])

  return (
    <ContentfulLivePreviewProvider
      locale="en-US"
      enableInspectorMode={pageProps.draftMode}
      enableLiveUpdates={pageProps.draftMode}
      debugMode={false}
    >
      <DataProvider value={{ email, setEmail }}>
        <Munchkin />
        <Component {...pageProps} />
      </DataProvider>
    </ContentfulLivePreviewProvider>
  )
}
