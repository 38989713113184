import React from "react"
import { useRouter } from "next/router"

import useMarketo from "./useMarketo"
import PropTypes from "prop-types"
import classNames from "classnames"
import { container, container_two_col, box_shadow } from "./styles.module.scss"

const { parseResourceType } = require("../../lib/utils")

const transform = (name) => {
  switch (name) {
    case "Content download":
      return "1817"
    case "Content download > Partner opt-in":
      return "1915"
    case "Event registration":
      return "1818"
    case "Event registration > Partner opt-in":
      return "1914"
    case "Webinar registration > On-demand":
      return "1917"
    case "Webinar registration > Upcoming":
      return "1823"
    case "Webinar registration > Upcoming > Partner opt-in":
      return "1913"
    case "Webinar registration > International":
      return "2156"
    default:
      return name
  }
}

const MarketoForm = (props) => {
  const { boxShadow, className, contentKey, formType, isTwoColumns, label, redirectUrl } = props

  const classes = classNames(className, container, {
    [box_shadow]: boxShadow,
    [container_two_col]: isTwoColumns
  })

  const router = useRouter()
  const formId = props.formId ?? transform(formType)
  let redirectTo

  // If there is a redirectUrl set in contentful, that should supersede other thankyou pages.
  if (redirectUrl) {
    redirectTo = redirectUrl
  } else if (props.slug) {
    redirectTo =
      // if it's event page, use the event page confirmation url pattern otherwise, use the resources one
      props.resourceType === "event"
        ? `/events/thank-you/${props.slug}`
        : `/resources/thank-you/${parseResourceType(props.resourceType)}/${props.slug}`
  } else if (props.label && router?.query?.slug) {
    redirectTo = `/thank-you/${router.query.slug}`
  }

  useMarketo({
    formId,
    contentKey,
    baseUrl: "//pages.amperity.com",
    munchkinId: "904-MJR-117",
    callback: () => {
      if (redirectTo) {
        // this is the GA Event - figure out if we want to remove it later pls
        // eslint-disable-next-line
        dataLayer.push({
          event: "marketoFormSubmit",
          formId: formId,
          contentKey: contentKey
        })

        // push the matomo event
        if (window._paq) {
          window._paq.push([
            "trackEvent",
            "marketoFormSubmit",
            `Current Page: ${router.asPath}`,
            `${formId}: ${formType} - ${contentKey}`
          ])
        }
        router.push(redirectTo, false)
        return false
      }
    }
  })

  return (
    <div className={classes}>
      <div className="mkto_container">
        {label && <h3>{label}</h3>}
        <form id={`mktoForm_${formId}`} data-testid="marketo-form" />
      </div>
    </div>
  )
}

export default MarketoForm

MarketoForm.propTypes = {
  /**
   * Marketo form id determines which form to show the user.
   */
  formId: PropTypes.string,
  /**
   * Marketo form type which gets transformed into an ID to render the form to show the user.
   */
  formType: PropTypes.string,
  /**
   * Label displayed above the marketo form.
   */
  label: PropTypes.string,
  /**
   * Non-visible attribute to help map user journeys
   */
  contentKey: PropTypes.string,
  /**
   * Redirect the form after submission.
   */
  redirectUrl: PropTypes.string,
  /**
   * Copy that will render on the thank you page of corresponding form.
   */
  boxShadow: PropTypes.bool,
  thankYouCopy: PropTypes.object,
  slug: PropTypes.string,
  formSlug: PropTypes.string,
  resourceType: PropTypes.string,
  className: PropTypes.string,
  isTwoColumns: PropTypes.bool
}
